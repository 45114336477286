import { useEffect, useState } from 'react';
import AdminService from '../../../../../Services/admin_service/AdminService';
import './Role.css';
import Select from 'react-select';
import Constants from '../../../../Constants';
import GlobalService from '../../../../../Services/global_service/GlobalService';
import LoadingBar from '../../../../loading_bar_component/LoadingBar';

function Role() {
    const [loadingBar, setLoadingBar] = useState(false);
    const [messageColor, setMessageColor] = useState(Constants.MESSAGE_COLOR);
    const [roleName, setRoleName] = useState('');
    const handleRoleName = (e) => setRoleName(e.target.value);
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        const getAllRoles = async () => {
            setLoadingBar(true);
            try {
                const responseData = await GlobalService.getAllRoles();
                console.log(responseData);
                setRoleList(responseData);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoadingBar(false);
            }
        };
        getAllRoles();
    }, []);

    const [errMsgDiv, setErrMsgDiv] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const addRoleDisplayErrMsg = (errorMessage) => {
        setErrMsg(errorMessage);
        setErrMsgDiv(true);
        setTimeout(() => {
            setErrMsg("");
            setErrMsgDiv(false);
        }, Constants.SET_TIME_OUT_FOR_ERROR_MSG);
    };

    const addRoleDisplaySucMsg = (successMessage) => {
        setMessageColor('green');
        setErrMsg(successMessage);
        setErrMsgDiv(true);
        setTimeout(() => {
            setErrMsg("");
            setErrMsgDiv(false);
            setMessageColor(Constants.MESSAGE_COLOR);
        }, Constants.SET_TIME_OUT_FOR_SUCCESS_MSG);
    };

    const handleAddRole = async () => {
        if (roleName === null || roleName === '') {
            addRoleDisplayErrMsg('Role should not be null or empty');
        } else {
            setLoadingBar(true);
            const request = {
                roleName: roleName,
            };
            try {
                const responseData = await AdminService.addRole(request);
                console.log(responseData);
                // If the role is successfully added
                addRoleDisplaySucMsg('Role Added Successfully');
            } catch (error) {
                // Pass the error message to the error handler
                handleAddRoleError(error.message);
            } finally {
                setLoadingBar(false);
            }
        }
    };

    const handleAddRoleError = (errorStatus) => {
        // Log the error status to verify what is being returned
        console.log("Error Status: ", errorStatus);

        if (Constants.INVALID_REQUEST_FIELD === errorStatus) {
            addRoleDisplayErrMsg("Please enter valid input fields");
        } else if (Constants.EXISTING_ROLE === errorStatus) {
            // Check for EXISTING_ROLE and show the appropriate message
            addRoleDisplayErrMsg("Role already existed");
        } else if (Constants.BREEZE_DATABASE_EXCEPTION === errorStatus) {
            addRoleDisplayErrMsg("Sorry, Our service is down");
        } else {
            // Generic fallback message for unknown errors
            addRoleDisplayErrMsg("Could not process your request");
        }
    };

    const childColor = getComputedStyle(document.documentElement).getPropertyValue('--child-color');
    const whiteColor = getComputedStyle(document.documentElement).getPropertyValue('--white-color');

    const customCssForRoleFlds = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? childColor : '',
            color: state.isFocused ? whiteColor : '',
            ':hover': {
                backgroundColor: childColor,
                color: whiteColor,
            },
        }),
        control: (provided, state) => ({
            ...provided,
            maxWidth: '400px',
        }),
        menu: (provided, state) => ({
            ...provided,
            maxWidth: '400px',
        }),
    };

    const customCssForMsg = {
        fontSize: 'medium',
        fontWeight: '700',
        color: messageColor,
    };

    return (
        <div className="role">
            {loadingBar && <LoadingBar />}

            {/* Enter New Role Label and Input */}
            <div className="add-role">
                <label htmlFor="roleNameInput">Enter New Role</label>
                <input
                    id="roleNameInput"  // Adding the 'id' for better accessibility
                    type="text"
                    className="add-role-input"
                    value={roleName}
                    onChange={handleRoleName}
                />
                {errMsgDiv && (
                    <div style={customCssForMsg}>
                        <label>{errMsg}</label>
                    </div>
                )}
            </div>

            {/* Add Button */}
            <div className="add-role-button-container mt-3">
                <button
                    className="dashboard-button"
                    onClick={handleAddRole}
                >
                    Add
                </button>
            </div>

            {/* View Roles Dropdown */}
            <div className="view-role mt-5">
                <label>View Roles</label>
                <Select
                    options={roleList}
                    getOptionLabel={(options) => options["roleName"]}
                    getOptionValue={(options) => options["roleName"]}
                    placeholder="Present Roles"
                    styles={customCssForRoleFlds}
                />
            </div>
        </div>
    );
}

export default Role;
