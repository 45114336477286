import './Register.css';
import { useState } from "react";
import { Dropdown, Form } from 'react-bootstrap';
import Constants from '../Constants';
import UserService from '../../Services/user_service/UserService';
import LoadingBar from '../loading_bar_component/LoadingBar';

function RegisterForm() {
  const [loadingBar, setLoadingBar] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({
    userFirstname: '',
    userLastname: '',
    username: '',
    password: '',
    confirmPassword: '',
    userType: '',
    exponentTypeList: [],
    companyName: '' // Added companyName field
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState('');
  const [errMsgDiv, setErrMsgDiv] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [sucMsgDiv, setSucMsgDiv] = useState(false);
  const [sucMsg, setSucMsg] = useState("");

  // Regular Expressions for validation
  const onlyLettersRegex = /^[A-Za-z]+$/;
  const passwordPatternRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&])(?=.*\d).{8,15}$/;

  const handleRegisterFormData = (e) => {
    const { name, value } = e.target;
    setRegisterFormData({
      ...registerFormData,
      [name]: value
    });
  };

  const handleCheckboxChange = (option) => {
    const newSelectedOptions = [...selectedOptions];
    const selectedIndex = newSelectedOptions.indexOf(option);
    if (selectedIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(selectedIndex, 1);
    }
    setSelectedOptions(newSelectedOptions);
    setSelectedRadio('');
  };

  const handleRadioChange = (option) => {
    setSelectedRadio(option);
    setSelectedOptions([]);
  };

  const innerHtmlButton = () => {
    if (selectedOptions.length > 0) {
      return selectedOptions.join(', ');
    } else if (selectedRadio !== '') {
      return selectedRadio;
    } else {
      return 'How would you like to Register';
    }
  };

  const registerFormDisplayErrMsg = (errorMessage) => {
    setErrMsg(errorMessage);
    setErrMsgDiv(true);
    setTimeout(() => {
      setErrMsg("");
      setErrMsgDiv(false);
    }, Constants.SET_TIME_OUT_FOR_ERROR_MSG);
  };

  const registerFormDisplaySucMsg = (successMessage) => {
    setSucMsg(successMessage);
    setSucMsgDiv(true);
    setTimeout(() => {
      setSucMsg("");
      setSucMsgDiv(false);
    }, 1500);
  };

  const validateFirstName = () => {
    return onlyLettersRegex.test(registerFormData.userFirstname) && registerFormData.userFirstname.length >= 3;
  };

  const validateLastName = () => {
    return onlyLettersRegex.test(registerFormData.userLastname) && registerFormData.userLastname.length >= 3;
  };

  const validateEmail = () => {
    return (registerFormData.username.slice(-4)).includes('.co');
  };

  const validatePassword = () => {
    return passwordPatternRegex.test(registerFormData.password);
  };

  const validateConfirmPassword = () => {
    return registerFormData.password === registerFormData.confirmPassword;
  };

  const validateCompanyName = () => {
    return registerFormData.companyName.trim().length > 0;
  };

  const registerSubmitForm = async (e) => {
    e.preventDefault();
    
    // Validation
    if (!validateFirstName()) {
      return registerFormDisplayErrMsg("First Name must only contain letters and be at least 3 characters long");
    } else if (!validateLastName()) {
      return registerFormDisplayErrMsg("Last Name must only contain letters and be at least 3 characters long");
    } else if (!validateEmail()) {
      return registerFormDisplayErrMsg("Please provide a valid email address (must end with .co)");
    } else if (!validatePassword()) {
      return registerFormDisplayErrMsg("Password must contain at least 1 uppercase letter, 1 digit, 1 special character, and be between 8-15 characters long");
    } else if (!validateConfirmPassword()) {
      return registerFormDisplayErrMsg("Password and Confirm Password must match");
    } else if (!validateCompanyName()) {
      return registerFormDisplayErrMsg("Company Name cannot be empty");
    } else if (selectedOptions.length === 0 && selectedRadio === '') {
      return registerFormDisplayErrMsg("Please select how you want to register");
    }

    // Prepare form data
    setLoadingBar(true);
    if (selectedOptions.length > 0) {
      registerFormData.exponentTypeList = [...selectedOptions];
      registerFormData.userType = "EXPONENT";
    }
    if (selectedRadio !== '') {
      registerFormData.exponentTypeList = [];
      registerFormData.userType = "CANDIDATE";
    }

    try {
      const responseData = await UserService.registerUser(registerFormData);
      localStorage.setItem('userId', JSON.stringify(responseData.userId));
      registerFormDisplaySucMsg('Registered Successfully...');
      setTimeout(() => {
        window.location.href = '/verify-email';
      }, 1500);
    } catch (error) {
      handleRegisterUserError(error.message);
    } finally {
      setLoadingBar(false);
    }
  };

  const handleRegisterUserError = (errorStatus) => {
    const errorMessages = {
      [Constants.INVALID_REQUEST_FIELD]: "Please enter valid input fields",
      [Constants.EXISTING_USERNAME]: "Mail-Id already exists",
      [Constants.ADDRESS_EXCEPTION]: "Please check the email syntax",
      [Constants.MESSAGING_EXCEPTION]: "Could not send mail, Please try again",
      [Constants.BREEZE_DATABASE_EXCEPTION]: "Sorry, Our service is down"
    };
    
    registerFormDisplayErrMsg(errorMessages[errorStatus] || "Could not process your request");
  };

  return (
    <div className="Register-form-component">
      {loadingBar && <LoadingBar />}
      <div className="Register-form-heading">
        <h2 className='my-3'>Registration</h2>
      </div>
      <div className="Register-form">
        <form onSubmit={registerSubmitForm} className="form">
          {/* Form Fields */}
          <div className="Register-txt-field">
            <input
              type="text"
              name="userFirstname"
              value={registerFormData.userFirstname}
              onChange={handleRegisterFormData}
              required
              pattern="[A-Za-z]{3,}"
              title="Only letters are allowed and minimum length should be 3"
            />
            <span></span>
            <label>First Name</label>
          </div>
          <div className="Register-txt-field">
            <input
              type="text"
              name="userLastname"
              value={registerFormData.userLastname}
              onChange={handleRegisterFormData}
              required
              pattern="[A-Za-z]{3,}"
              title="Only letters are allowed and minimum length should be 3"
            />
            <span></span>
            <label>Last Name</label>
          </div>
          <div className="Register-txt-field">
            <input
              type="email"
              name="username"
              value={registerFormData.username}
              onChange={handleRegisterFormData}
              required
            />
            <span></span>
            <label>Mail-Id</label>
          </div>
          <div className="Register-txt-field">
            <input
              type="password"
              name="password"
              value={registerFormData.password}
              onChange={handleRegisterFormData}
              required
              pattern="^(?=.*[A-Z])(?=.*[!@#$%^&])(?=.*\d).{8,15}$"
              title="Password should contain 1 uppercase, 1 digit, 1 Special Character, and length between 8-15."
            />
            <span></span>
            <label>Password</label>
          </div>
          <div className="Register-txt-field">
            <input
              type="password"
              name="confirmPassword"
              value={registerFormData.confirmPassword}
              onChange={handleRegisterFormData}
              required
            />
            <span></span>
            <label>Confirm Password</label>
          </div>
          <div className="Register-txt-field">
            <input
              type="text"
              name="companyName"
              value={registerFormData.companyName}
              onChange={handleRegisterFormData}
              required
              pattern="[A-Za-z\s]{3,}"
              title="Only letters and spaces are allowed, and the minimum length should be 3"
            />
            <span></span>
            <label>Company Name</label>
          </div>

          {/* Multi Select Dropdown */}
          <div className="multiSelectDropdownInRegister">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="Register-dropdown-button">
                <span className="Text-in-register-dropdown">{innerHtmlButton()}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-2">
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="Trainer"
                    label="Trainer"
                    checked={selectedOptions.includes(Constants.TRAINER)}
                    onChange={() => handleCheckboxChange(Constants.TRAINER)}
                  />
                  <Form.Check
                    type="checkbox"
                    id="Job-Support"
                    label="Job-Supporter"
                    checked={selectedOptions.includes(Constants.JOB_SUPPORTER)}
                    onChange={() => handleCheckboxChange(Constants.JOB_SUPPORTER)}
                  />
                  <Form.Check
                    type="checkbox"
                    id="Interview-Support"
                    label="Interview-Supporter"
                    checked={selectedOptions.includes(Constants.INTERVIEW_SUPPORTER)}
                    onChange={() => handleCheckboxChange(Constants.INTERVIEW_SUPPORTER)}
                  />
                  <Form.Check
                    type="radio"
                    id="Candidate"
                    label="Candidate"
                    checked={selectedRadio === Constants.CANDIDATE}
                    onChange={() => handleRadioChange(Constants.CANDIDATE)}
                  />
                </Form>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Error and Success Messages */}
          {errMsgDiv && <div className="Register-div-for-err-msg"><label>{errMsg}</label></div>}
          {sucMsgDiv && <div className="Register-div-for-suc-msg"><label>{sucMsg}</label></div>}

          {/* Submit Button */}
          <div>
            <button type="submit" className="btn Register-breeze-button px-4">Register</button>
          </div>
          <div className="Register-label-already-have">
            Already have an account?&nbsp;
            <a href="./login" className="Register-anchor-navigate">Login</a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
