import { useState } from 'react';
import './SubAdmin.css';
import Constants from '../../../../Constants';
import UserService from '../../../../../Services/user_service/UserService';
import LoadingBar from '../../../../loading_bar_component/LoadingBar';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Select from 'react-select'; // Import Select component for dropdowns
import { Country, State, City } from 'country-state-city';

function CreateSubAdmin() {

    const [loadingBar, setLoadingBar] = useState(false);

    const [subAdminForm, setSubAdminForm] = useState({
        userFirstname: '',
        userLastname: '',
        username: '',
        password: '',
        confirmPassword: '',
        userType: Constants.SUB_ADMIN,
        phoneNumber: '',
        countryCode: '',
        phoneNumberWithCountryCode: null,
        regionCode: '',
        companyName: '', // Add companyName field here
        gender: '',
        state: '',
        city: '',
        country: ''
    });

    const handleCreateSubAdminFormFields = (e) => {
        const { name, value } = e.target;
        setSubAdminForm({
            ...subAdminForm,
            [name]: value
        });
    };

    const genderList = [
        { name: 'Male' },
        { name: 'Female' },
        { name: 'Others' }
    ];

    const onSelectGender = (item) => {
        setSubAdminForm({
            ...subAdminForm,
            gender: item.name
        });
    }

    const onlyLettersRegex = /^[A-Za-z]+$/;
    const passwordPatternRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,15}$/;

    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [phoneWithCountryCode, setPhoneWithCountryCode] = useState(null);
    const [iso2, setIso2] = useState('');
    const handleContactInfo = (phone, country) => {
        const countryCodeWithPlus = '+' + country.country.dialCode;
        const phoneWithOutCountryCode = phone.replace(countryCodeWithPlus, '');
        setPhone(phoneWithOutCountryCode);
        setPhoneWithCountryCode(country.inputValue);
        setCountryCode(country.country.dialCode);
        setIso2(country.country.iso2);
        updateCountryWhenPhoneNumberChanged(country.country.iso2);
    }
    
    const updateCountryWhenPhoneNumberChanged = (selectedIso2Code) => {
        if (selectedIso2Code) {
            const countries = Country.getAllCountries();
            const foundCountry = countries.find(country => country.isoCode === selectedIso2Code.toUpperCase());
            if (foundCountry) {
                setSelectedCountry(foundCountry);
            }
        }
    }

     const [verifyEmailForm, setVerifyEmailForm] = useState({
            userId: '',
            emailOtp: ''
        });
        const [otpForm, setOtpForm] = useState({
            contactOtp: '',
            emailOtp: ''
        });
// Country, state, city code
const [selectedCountry, setSelectedCountry] = useState(null);
const [selectedState, setSelectedState] = useState(null);
const [selectedCity, setSelectedCity] = useState(null);
    const handleCreateSubAdmin = async (e) => {
        e.preventDefault();
        const matchConfirmPassword = subAdminForm.password === subAdminForm.confirmPassword;

        if (!onlyLettersRegex.test(subAdminForm.userFirstname)) {
            createSubAdminDisplayErrMsg("First Name is not matching the pattern");
        } else if (!onlyLettersRegex.test(subAdminForm.userLastname)) {
            createSubAdminDisplayErrMsg("Last Name is not matching the pattern");
        } else if (!((subAdminForm.username).slice(-4)).includes('.co')) {
            createSubAdminDisplayErrMsg("Please provide valid mail address");
        } else if (phone === null || phone === '' || phone === undefined) {
            createSubAdminDisplayErrMsg("Please enter sub admin contact information");
        } else if (!passwordPatternRegex.test(subAdminForm.password)) {
            createSubAdminDisplayErrMsg("Password is not matching the pattern");
        } else if (!matchConfirmPassword) {
            createSubAdminDisplayErrMsg("Confirm Password not matching Password");
        } else if (subAdminForm.companyName === '') { // Check if companyName is provided
            createSubAdminDisplayErrMsg("Please enter the company name");
        }
     else if (subAdminForm.gender === '') { 
        createSubAdminDisplayErrMsg("Please enter the gender");       
} else if (selectedState === null || selectedState=== null) { 
        createSubAdminDisplayErrMsg("Please enter the state & city");    
 } else {
            setLoadingBar(true);
            subAdminForm.phoneNumber = phone;
            subAdminForm.countryCode = countryCode;
            subAdminForm.phoneNumberWithCountryCode = phoneWithCountryCode;
            subAdminForm.regionCode = iso2;
             subAdminForm.country = selectedCountry?.name || '';  
            subAdminForm.state = selectedState?.name || '';  
             subAdminForm.city = selectedCity?.name || '';

            console.log(subAdminForm);
            try {
                const responseData = await UserService.createSubAdmin(subAdminForm);
                console.log(responseData);
                verifyEmailForm.userId = responseData.userId;
                createSubAdminDisplaySucMsg('Sub Admin Registered Successfully');
            } catch (error) {
                handleCreateSubAdminError(error.message);
            } finally {
                setLoadingBar(false);
            }
        }
    }

    const [errMsgDivForEmailVerify, setErrMsgDivForEmailVerify] = useState(false);
    const [errMsgForEmailVerify, setErrMsgForEmailVerify] = useState("");
    const createAdminDisplaySucMsgForEmailVerify = (errorMessage) => {
            setErrMsgForEmailVerify(errorMessage);
            setErrMsgDivForEmailVerify(true);
            setMessageColor('green');
            setTimeout(() => {
                setErrMsgForEmailVerify("");
                setErrMsgDivForEmailVerify(false);
                setMessageColor(Constants.MESSAGE_COLOR);
                handleCloseModal();
            }, Constants.SET_TIME_OUT_FOR_SUCCESS_MSG);
        }
    
        const handleVerifyEmailError = (errorStatus) => {
                if (Constants.INVALID_REQUEST_FIELD === errorStatus)
                    createAdminDisplayErrMsgForEmailVerify("Please enter valid inputs fields");
                else if (Constants.OTP_NOT_MATCHED_FOR_MAIL === errorStatus)
                    createAdminDisplayErrMsgForEmailVerify("Please enter correct OTP for mail");
                else if (Constants.OTP_NOT_MATCHED_FOR_CONTACT === errorStatus)
                    createAdminDisplayErrMsgForEmailVerify("Please enter correct OTP for contact");
                else if (Constants.OTP_NOT_MATCHED === errorStatus)
                    createAdminDisplayErrMsgForEmailVerify("Please enter correct OTP");
                else if (Constants.ENTITY_NOT_FOUND === errorStatus)
                    createAdminDisplayErrMsgForEmailVerify("Your registration is not found");
                else if (Constants.BREEZE_DATABASE_EXCEPTION === errorStatus)
                    createAdminDisplayErrMsgForEmailVerify("Sorry, Our service is down");
                else
                    createAdminDisplayErrMsgForEmailVerify("Could not process your request");
            }

            const createAdminDisplayErrMsgForEmailVerify = (errorMessage) => {
                setErrMsgForEmailVerify(errorMessage);
                setErrMsgDivForEmailVerify(true);
                setTimeout(() => {
                    setErrMsgForEmailVerify("");
                    setErrMsgDivForEmailVerify(false);
                }, Constants.SET_TIME_OUT_FOR_ERROR_MSG);
            }
    const verifyEmailSubmitForm = async (e) => {
        e.preventDefault();
        setLoadingBar(true);
        verifyEmailForm.emailOtp = otpForm.emailOtp + otpForm.contactOtp;
        try {
            const responseData = await UserService.verifyAdmin(verifyEmailForm);
            createAdminDisplaySucMsgForEmailVerify("OTP Verified Successfully");
        } catch (error) {
            handleVerifyEmailError(error.message);
        } finally {
            setLoadingBar(false);
        }
    }
    const handleVerifyEmailFormData = (e) => {
        const { name, value } = e.target;
        setOtpForm({
            ...otpForm,
            [name]: value
        });
    };

    const handleCreateSubAdminError = (errorStatus) => {
        if (Constants.INVALID_REQUEST_FIELD === errorStatus)
            createSubAdminDisplayErrMsg("Please enter valid input fields");
        else if (Constants.EXISTING_USERNAME === errorStatus)
            createSubAdminDisplayErrMsg("Email-Id is already existed");
        else if (Constants.ADDRESS_EXCEPTION === errorStatus)
            createSubAdminDisplayErrMsg("Please check, Email syntax");
        else if (Constants.MESSAGING_EXCEPTION === errorStatus)
            createSubAdminDisplayErrMsg("Could not send email, Please try again");
        else if (Constants.INVALID_PHONE_NUMBER === errorStatus)
            createSubAdminDisplayErrMsg("Entered Phone Number is Invalid");
        else if (Constants.OTP_NOT_SENT === errorStatus)
            createSubAdminDisplayErrMsg("Failed to send OTP");
        else if (Constants.BREEZE_DATABASE_EXCEPTION === errorStatus)
            createSubAdminDisplayErrMsg("Sorry, Our service is down");
        else
            createSubAdminDisplayErrMsg("Could not process your request");
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
        window.location.reload();
    }

    const [errMsgDiv, setErrMsgDiv] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [messageColor, setMessageColor] = useState(Constants.MESSAGE_COLOR);
    const createSubAdminDisplayErrMsg = (errorMessage) => {
        setErrMsg(errorMessage);
        setErrMsgDiv(true);
        setTimeout(() => {
            setErrMsg("");
            setErrMsgDiv(false);
        }, Constants.SET_TIME_OUT_FOR_ERROR_MSG);
    }
    const createSubAdminDisplaySucMsg = (successMessage) => {
        setErrMsg(successMessage);
        setErrMsgDiv(true);
        setMessageColor('green');
        setTimeout(() => {
            setErrMsg("");
            setErrMsgDiv(false);
            setMessageColor(Constants.MESSAGE_COLOR);
            setShowModal(true);
        }, Constants.SET_TIME_OUT_FOR_SUCCESS_MSG);
    }

    const customCssForMsg = {
        fontSize: 'medium',
        fontWeight: '700',
        color: messageColor,
    }

    return (
        <div className='create-sub-admin-users'>
            {loadingBar && <LoadingBar />}
            <div className='admin-form'>
                <form onSubmit={handleCreateSubAdmin}>
                    <div>
                        <label>First Name</label>
                        <input
                            className='form-control'
                            placeholder='First Name'
                            name='userFirstname'
                            onChange={handleCreateSubAdminFormFields}
                            type='text'
                            required
                        />
                    </div>
                    <div className='mt-2'>
                        <label>Last Name</label>
                        <input
                            className='form-control'
                            placeholder='Last Name'
                            name='userLastname'
                            onChange={handleCreateSubAdminFormFields}
                            type='text'
                            required
                        />
                    </div>
                    <div className='mt-2'>
                        <label>Mail Id</label>
                        <input
                            className='form-control'
                            placeholder='Mail Id'
                            name='username'
                            onChange={handleCreateSubAdminFormFields}
                            type='email'
                            required
                        />
                    </div>
                    <div className='mt-2'>
                        <label>Contact Info</label>
                        <PhoneInput
                            forceDialCode={true}
                            onChange={handleContactInfo}
                            inputStyle={{ width: '100%' }}
                        />
                    </div>
                    <div className='mt-2'>
                        <label>Password</label>
                        <input
                            className='form-control'
                            placeholder='Password'
                            name='password'
                            onChange={handleCreateSubAdminFormFields}
                            type='password'
                            required
                        />
                    </div>
                    <div className='mt-2'>
                        <label>Confirm Password</label>
                        <input
                            className='form-control'
                            placeholder='Confirm Password'
                            name='confirmPassword'
                            onChange={handleCreateSubAdminFormFields}
                            type='password'
                            required
                        />
                    </div>
                      {/* Company Name Input */}
                      <div className='mt-2'>
                        <label>Company Name</label>
                        <input
                            className='form-control'
                            placeholder='Company Name'
                            name='companyName'
                            value={subAdminForm.companyName}
                            onChange={handleCreateSubAdminFormFields}
                            type='text'
                            required
                            pattern="[A-Za-z\s]{3,}"
                            title="Only letters and spaces are allowed, and the minimum length should be 3"
                        />
                    </div>
                    {/* Gender Selection */}
                    <div className="gender-singleselect">
                        <label>Gender</label>
                        <Select
                            id="gender"
                            options={genderList}
                            getOptionLabel={(options) => options["name"]}
                            getOptionValue={(options) => options["name"]}
                            onChange={onSelectGender}
                            placeholder="Select Gender"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    width: '100%',
                                }),
                            }}
                        />
                    </div>

                    {/* Country, State, City Selection */}
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <div className="country">
                                    <label>
                                       Country
                                    </label>
                                    <Select
                                        options={Country.getAllCountries()}
                                        getOptionLabel={(options) => options["name"]}
                                        getOptionValue={(options) => options["name"]}
                                        value={selectedCountry}
                                        onChange={(item) => {
                                            setSelectedCountry(item);
                                        }}
                                        placeholder="Select Country"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                        isDisabled={true}
                                    />
                                </div>
                            </Col>

                            <Col>
                                <div className="state">
                                    <label>
                                       State
                                    </label>
                                    <Select
                                        options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                        getOptionLabel={(options) => options["name"]}
                                        getOptionValue={(options) => options["name"]}
                                        value={selectedState}
                                        onChange={(item) => {
                                            setSelectedState(item);
                                        }}
                                        placeholder="Select State"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </div>
                            </Col>

                            <Col>
                                <div className="city">
                                    <label>
                                       City
                                    </label>
                                    <Select
                                        options={City.getCitiesOfState(
                                            selectedState?.countryCode,
                                            selectedState?.isoCode
                                        )}
                                        getOptionLabel={(options) => options["name"]}
                                        getOptionValue={(options) => options["name"]}
                                        value={selectedCity}
                                        onChange={(item) => {
                                            setSelectedCity(item);
                                        }}
                                        placeholder="Select City"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {errMsgDiv && 
                        <div style={customCssForMsg}>
                            <label>{errMsg}</label>
                        </div>
                    }

                    <div className='mt-2'>
                        <button className='dashboard-button' type='submit'>
                            Create
                        </button>
                    </div>
                </form>
            </div>

          {showModal && (
                          <Modal className="admin-email-verify-modal" size="md" show={showModal} onHide={handleCloseModal} centered backdrop="static">
                              <Container className="px-5">
                                  <Modal.Header closeButton>
                                      <Modal.Title style={{ fontSize: '18px' }}>
                                          Verify Email & Contact Number
                                      </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                      <form onSubmit={verifyEmailSubmitForm}>
                                          <div className="row">
                                              <div className="col-12">
                                                  <label>Enter OTP sent to Admin Mail Id</label>
                                                  <input
                                                      className="form-control"
                                                      type="text"
                                                      name="emailOtp"
                                                      onChange={handleVerifyEmailFormData}
                                                      pattern="\d{6}"
                                                      required
                                                  />
                                              </div>
                                              <div className="col-12 mt-2">
                                                  <label>Enter OTP sent to Admin Contact Number</label>
                                                  <input
                                                      className="form-control"
                                                      type="text"
                                                      name="contactOtp"
                                                      onChange={handleVerifyEmailFormData}
                                                      pattern="\d{6}"
                                                      required
                                                  />
                                              </div>
                                          </div>
                                          {errMsgDivForEmailVerify && (
                                              <div style={customCssForMsg}>
                                                  <label>{errMsgForEmailVerify}</label>
                                              </div>
                                          )}
                                          <div className="verify-button mt-2">
                                              <button type="submit" className="dashboard-button px-3">Verify</button>
                                          </div>
                                      </form>
                                  </Modal.Body>
                              </Container>
                          </Modal>
                      )}
        </div>
    );
}

export default CreateSubAdmin;
