import axios from "../UnauthorizedAxiosResponse";
import Constants from "../../components/Constants";

const InterviewSupporterService = {

    createInterviewSupport: async (requestData) => {
        try {
            const companyName = localStorage.getItem('breezeCompanyName'); // Retrieve company name from localStorage
            const jwtToken = localStorage.getItem('breezeJwtToken'); // Retrieve JWT token
    
            // Construct the URL with companyName as a query parameter
            const url = `${Constants.BASE_URL}/interview-supporter/create-interview-support?companyName=${encodeURIComponent(companyName)}`;
    
            // Make the API call with requestData in the body and the Authorization header
            const response = await axios.post(url, requestData, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`, // Authorization header with Bearer token
                }
            });
    
            return response.data;
        } catch (error) {
            console.log(error);
            // Improved error handling with fallback to error message
            throw new Error(error.response ? error.response.data.status : error.message); 
        }
    },
    

    exponentRejectedInterviewSupport: async (requestData, companyName) => {
        try {
            const companyName = localStorage.getItem('breezeCompanyName'); // Retrieve company name from localStorage
            const response = await axios.post(`${Constants.BASE_URL}/interview-supporter/rejected-interview-support-by-exponent-id?companyName=${companyName}`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status); 
        }
    },
    
    

    updateRejectedInterviewSupport: async (requestData) => {
        try {
            const companyName = localStorage.getItem('breezeCompanyName');
            const response = await axios.post(`${Constants.BASE_URL}/interview-supporter/update-rejected-interview-support?companyName=${companyName}`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status); 
        }
    },

    exponentApprovedInterviewSupport: async (requestData, companyName) => {
        try {
            const companyName = localStorage.getItem('breezeCompanyName'); // Retrieve company name from localStorage
            const response = await axios.post(`${Constants.BASE_URL}/interview-supporter/approved-interview-support-by-exponent-id?companyName=${companyName}`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status); 
        }
    },
    
    
    updateApprovedInterviewSupport: async (requestData) => {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/interview-supporter/update-approved-interview-support`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status); 
        }
    },

    getInterviewSupporterBookingByExponentId: async (requestData, companyName) => {
        try {
      const companyName = localStorage.getItem('breezeCompanyName');
            const response = await axios.post(`${Constants.BASE_URL}/interview-supporter/get-interview-supporter-bookings?companyName=${companyName}`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status); 
        }
    },
    
    getTodayInterviewSupporterBookingByExponentId: async (requestData, companyName) => {
        try {
      const companyName = localStorage.getItem('breezeCompanyName');
            const response = await axios.post(`${Constants.BASE_URL}/interview-supporter/get-today-interview-supporter-bookings?companyName=${companyName}`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status); 
        }
    },
    
    getCompletedInterviewSupporterBookingByExponentId: async (requestData, companyName) => {
        try {
      const companyName = localStorage.getItem('breezeCompanyName');
            const response = await axios.post(`${Constants.BASE_URL}/interview-supporter/get-interview-supporter-completed-bookings?companyName=${companyName}`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status); 
        }
    },
    
}

export default InterviewSupporterService;