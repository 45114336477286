import './MandatoryUpdateFields.css';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Multiselect from "multiselect-react-dropdown";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Select from 'react-select';
import { City, Country, State } from 'country-state-city';
import GlobalService from '../../../../Services/global_service/GlobalService';
import Constants from '../../../Constants';
import UserService from '../../../../Services/user_service/UserService';
import LoadingBar from '../../../loading_bar_component/LoadingBar';

function MandatoryUpdateFields() {

    const userId = localStorage.getItem("breezeUserId");
const userType = localStorage.getItem("breezeUserType");
const [roleName, setRoleName] = useState('');
const [loadingBar, setLoadingBar] = useState(false);
const [userRole, setUserRole] = useState(null); 
const [messageColor, setMessageColor] = useState(Constants.MESSAGE_COLOR);
const [roleList, setRoleList] = useState([]);
const [showModal, setShowModal] = useState(true);
const handleModalClose = () => setShowModal(false);

const selectedRole = roleList.find(role => role.name === roleName);
const childColor = getComputedStyle(document.documentElement).getPropertyValue('--child-color');
const whiteColor = getComputedStyle(document.documentElement).getPropertyValue('--white-color');

useEffect(() => {
    const getAllRoles = async () => {
        setLoadingBar(true);
        try {
            const responseData = await GlobalService.getAllRoles();
            console.log('Role List:', responseData);
            setRoleList(responseData);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoadingBar(false);
        }
    };
    getAllRoles();
}, []);


const customCssForRoleFlds = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? childColor : '',
        color: state.isFocused ? whiteColor : '',
        ':hover': {
            backgroundColor: childColor,
            color: whiteColor,
        },
    }),
    control: (provided, state) => ({
        ...provided,
        maxWidth: '400px',
    }),
    menu: (provided, state) => ({
        ...provided,
        maxWidth: '400px',
    }),
};

// Fields for displaying error message div and label
const [errMsgDiv, setErrMsgDiv] = useState(false);
const [errMsg, setErrMsg] = useState("");
// JS for displaying error message
const mandatoryUpdateFieldsDisplayErrMsg = (errorMessage) => {
    setErrMsg(errorMessage);
    setErrMsgDiv(true);
    setTimeout(() => {
        setErrMsg("");
        setErrMsgDiv(false);
    }, Constants.SET_TIME_OUT_FOR_ERROR_MSG);
}

// JS for displaying success message
const mandatoryUpdateFieldsDisplaySucMsg = (errorMessage) => {
    setMessageColor('green');
    setErrMsg(errorMessage);
    setErrMsgDiv(true);
    setTimeout(() => {
        setErrMsg("");
        setErrMsgDiv(false);
        setMessageColor('#be3144');
    }, Constants.SET_TIME_OUT_FOR_SUCCESS_MSG);
}

// Experience code
const [experience, setExperience] = useState(0);
const handleExperienceInput = (e) => setExperience(e.target.value);

// Phone code
const [phone, setPhone] = useState('');
const [countryCode, setCountryCode] = useState('');
const [phoneWithCountryCode, setPhoneWithCountryCode] = useState(null);
const [iso2, setIso2] = useState('');
const handleContactInfo = (phone, country) => {
    const countryCodeWithPlus = '+' + country.country.dialCode;
    const phoneWithOutCountryCode = phone.replace(countryCodeWithPlus, '');
    setPhone(phoneWithOutCountryCode);
    setPhoneWithCountryCode(country.inputValue);
    setCountryCode(country.country.dialCode);
    setIso2(country.country.iso2);
    updateCountryWhenPhoneNumberChanged(country.country.iso2);
}

const updateCountryWhenPhoneNumberChanged = (selectedIso2Code) => {
    if (selectedIso2Code) {
        const countries = Country.getAllCountries();
        const foundCountry = countries.find(country => country.isoCode === selectedIso2Code.toUpperCase());
        if (foundCountry) {
            setSelectedCountry(foundCountry);
        }
    }
}

// Gender code
const [gender, setGender] = useState('');
const genderList = [
    { name: 'Male' },
    { name: 'Female' },
    { name: 'Others' },
];
const onSelectGender = (item) => {
    setGender(item.name);
}

// Technology code
const [technologies, setTechnologies] = useState([]);
const [technologiesList, setTechnologiesList] = useState([]);
const onSelectTechnology = (selectedList, selectedItem) => {
    setTechnologies([...selectedList]);
}
const onRemoveTechnology = (selectedList, removedItem) => {
    setTechnologies([...selectedList]);
}

useEffect(() => {
    const getAllTechnology = async () => {
        setLoadingBar(true);
        try {
            const responseData = await GlobalService.getAllTechnology();
            console.log(responseData);
            setTechnologiesList(responseData);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoadingBar(false);
        }
    };
    getAllTechnology();
}, []);

// Country, state, city code
const [selectedCountry, setSelectedCountry] = useState(null);
const [selectedState, setSelectedState] = useState(null);
const [selectedCity, setSelectedCity] = useState(null);

const handleMandatoryUpdateFieldsSubmit = async () => {
    console.log(userType);
    if (userType === Constants.EXPONENT && (technologies === null || technologies.length === 0 || technologies.length < 3)) {
        mandatoryUpdateFieldsDisplayErrMsg('Please choose your minimum 3 technical stacks');
    } else if (userType === Constants.EXPONENT && (experience === null || experience === '' || experience === undefined || experience === 0)) {
        mandatoryUpdateFieldsDisplayErrMsg('Please enter your experience');
    } else if (userType === Constants.EXPONENT && (experience === null || experience === 0 || experience > 50)) {
        mandatoryUpdateFieldsDisplayErrMsg('Experience should be less than or equal to 50');
    } else if (!isContactInfoVerified) {
        mandatoryUpdateFieldsDisplayErrMsg('Verify your contact information');
    } else if (userType === Constants.EXPONENT && userRole === null) {
        mandatoryUpdateFieldsDisplayErrMsg('Please select your role');
    } else if (phone === null || phone === '' || phone === undefined) {
        mandatoryUpdateFieldsDisplayErrMsg('Please enter your contact information');
    } else if (gender === null || gender === '') {
        mandatoryUpdateFieldsDisplayErrMsg('Please select your gender');
    } else if (selectedCountry === null || selectedState === null || selectedCity === null) {
        mandatoryUpdateFieldsDisplayErrMsg('Please select your country, state, and city');
    } else {
        setLoadingBar(true);
        const request = {
            userId: userId,
            userType: userType,
            userRole: userRole,
            technologyList: technologies,
            phoneNumber: phone,
            countryCode: countryCode,
            phoneNumberWithCountryCode: phoneWithCountryCode,
            userExperience: experience,
            gender: gender,
            country: selectedCountry.name,
            state: selectedState.name,
            city: selectedCity.name,
        }
        console.log(request);
        try {
            const responseData = await UserService.mandatoryUpdateFields(request);
            console.log(responseData);
            localStorage.setItem('breezeUserFirstTimeLogin', responseData.firstTimeLogin);
            mandatoryUpdateFieldsDisplaySucMsg('Mandatory Fields Updated Successfully');
            setTimeout(() => {
                handleModalClose();
            }, 2000);
        } catch (error) {
            handleMandatoryUpdateFieldsErrors(error.message);
        } finally {
            setLoadingBar(false);
        }
    }
};

const handleMandatoryUpdateFieldsErrors = (errorStatus) => {
    if (Constants.INVALID_REQUEST_FIELD === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Please enter valid input fields");
    else if (Constants.ENTITY_NOT_FOUND === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("You are not registered");
    else if (Constants.BREEZE_DATABASE_EXCEPTION === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Sorry, Our service is down");
    else
        mandatoryUpdateFieldsDisplayErrMsg("Could not process your request");
}

const [isOtpSent, setIsOtpSent] = useState(false);
const handleSendOtp = async () => {
    if (phone === null || phone === '' || phone === undefined) {
        mandatoryUpdateFieldsDisplayErrMsg("Please enter your contact information");
    } else {
        setLoadingBar(true);
        const request = {
            countryCode: countryCode,
            phoneNumber: phone,
            phoneNumberWithCountryCode: phoneWithCountryCode,
            regionCode: iso2,
            userId: userId
        }
        console.log(request);
        try {
            const responseData = await UserService.sendOtpToPhoneNumber(request);
            console.log(responseData);
            mandatoryUpdateFieldsDisplaySucMsg(responseData);
            setIsOtpSent(true);
        } catch (error) {
            handleSendOtpToPhoneNumberErrors(error.message);
            setIsOtpSent(false);
        } finally {
            setLoadingBar(false);
        }
    }
}

const handleSendOtpToPhoneNumberErrors = (errorStatus) => {
    if (Constants.INVALID_REQUEST_FIELD === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Please enter valid contact information");
    else if (Constants.ENTITY_NOT_FOUND === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("You are not registered");
    else if (Constants.INVALID_PHONE_NUMBER === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Entered Phone Number is Invalid");
    else if (Constants.OTP_NOT_SENT === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Failed to send otp");
    else if (Constants.BREEZE_DATABASE_EXCEPTION === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Sorry, Our service is down");
    else
        mandatoryUpdateFieldsDisplayErrMsg("Could not process your request");
}

const handleEditContactInfo = () => {
    setIsOtpSent(false);
}

const [otp, setOtp] = useState('');
const handleOtpInput = (e) => setOtp(e.target.value);
const [isContactInfoVerified, setIsContactInfoVerified] = useState(false);
const handleVerifyOtp = async () => {
    if (otp === 0 || otp === null || otp === '' || otp === undefined) {
        mandatoryUpdateFieldsDisplayErrMsg("Please enter otp");
    } else if (otp.length !== 6) {
        mandatoryUpdateFieldsDisplayErrMsg("Invalid otp");
    } else {
        setLoadingBar(true);
        const request = {
            emailOtp: otp,
            userId: userId
        }
        console.log(request);
        try {
            const responseData = await UserService.verifyOtpToPhoneNumber(request);
            console.log(responseData);
            setIsContactInfoVerified(true);
        } catch (error) {
            handleVerifyOtpErrors(error.message);
            setIsContactInfoVerified(false);
        } finally {
            setLoadingBar(false);
        }
    }
}

const handleVerifyOtpErrors = (errorStatus) => {
    if (Constants.INVALID_REQUEST_FIELD === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Please enter valid otp");
    else if (Constants.ENTITY_NOT_FOUND === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("You are not registered");
    else if (Constants.OTP_NOT_MATCHED === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Entered Otp is Incorrect");
    else if (Constants.BREEZE_DATABASE_EXCEPTION === errorStatus)
        mandatoryUpdateFieldsDisplayErrMsg("Sorry, Our service is down");
    else
        mandatoryUpdateFieldsDisplayErrMsg("Could not process your request");
}
const onSelectRole = (selectedOption) => {
    setUserRole(selectedOption ? selectedOption.roleName : null);  // Ensure userRole is updated
};

<Select
    options={roleList}
    getOptionLabel={(options) => options["roleName"]}
    getOptionValue={(options) => options["roleName"]}
    placeholder="Select Role"
    onChange={onSelectRole}  // This should update userRole state
    styles={{
        control: (base) => ({
            ...base,
            width: '100%',
        }),
    }}
/>

const customCssForMsg = {
    fontSize: 'medium',
    fontWeight: '700',
    color: messageColor,
}
return (
    <div>
        {loadingBar && <LoadingBar />}
        <Modal className="mandatory-update-fields-modal" size="lg" show={showModal} onHide={handleModalClose} centered backdrop="static">
            <Container className="px-5">
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '20px', marginBottom: '10px' }}> {/* Reduce margin-bottom here */}
                        Mandatory Fields
                    </Modal.Title>
                </Modal.Header>
            </Container>
            <Modal.Body>
                <Container className="px-5" style={{ fontSize: '15px' }}>
                    {/* Role field with reduced margin-top */}
                    {userType === Constants.EXPONENT && (
                        <div className="view-role mt-2"> {/* Changed mt-5 to mt-2 */}
                            <label><span style={{ color: 'red' }}>*</span>Role</label>
                            <Select
                                options={roleList}
                                getOptionLabel={(options) => options["roleName"]}
                                getOptionValue={(options) => options["roleName"]}
                                placeholder="Select Role"
                                onChange={onSelectRole}  // Call this function when a role is selected
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        width: '100%',
                                    }),
                                }}
                            />
                        </div>
                    )}

                    {/* Technologies Field */}
                    {userType === Constants.EXPONENT && (
                        <div>
                            <label>
                                <span style={{ color: 'red' }}>*</span>Technologies
                            </label>
                            <Row>
                                <Col>
                                    <div className="technology-multiselect">
                                        <Multiselect
                                            id="technology"
                                            options={technologiesList}
                                            onSelect={onSelectTechnology}
                                            onRemove={onRemoveTechnology}
                                            displayValue="technologyName"
                                            placeholder="Your Technical Stack"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: childColor,
                                                },
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {/* Contact Info Field */}
                    <div className="mt-2">
                        <label>
                            <span style={{ color: 'red' }}>*</span>Contact Info
                        </label>
                        <Row>
                            <Col>
                                <div className="contact-info" style={{ display: 'flex' }}>
                                    <PhoneInput
                                        forceDialCode={true}
                                        onChange={handleContactInfo}
                                        inputStyle={{ width: '100%' }}
                                        disabled={isOtpSent}
                                    />
                                    {!isContactInfoVerified && (
                                        <Button className="button-otp" onClick={handleSendOtp} disabled={isOtpSent}>
                                            Send Otp
                                        </Button>
                                    )}
                                    {isContactInfoVerified && (
                                        <Button className="button-otp">
                                            Verified
                                        </Button>
                                    )}
                                    {isOtpSent && !isContactInfoVerified && (
                                        <Button className="button-otp" onClick={handleSendOtp}>
                                            ReSend
                                        </Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* OTP Input Field */}
                    {!isContactInfoVerified && isOtpSent && (
                        <div className="mt-2">
                            <label>
                                <span style={{ color: 'red' }}>*</span>Enter Otp
                            </label>
                            <Row>
                                <Col>
                                    <div className="contact-info" style={{ display: 'flex' }}>
                                        <input
                                            type="number"
                                            placeholder="Enter Otp"
                                            style={{ borderRadius: '4px' }}
                                            value={otp}
                                            onChange={handleOtpInput}
                                        />
                                        <Button className="button-otp" onClick={handleVerifyOtp}>
                                            Verify
                                        </Button>
                                        <Button className="button-otp" onClick={handleEditContactInfo}>
                                            Edit Contact Info
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {/* Experience and Gender fields */}
                    <div className="mt-2">
                        <Row>
                            {userType === Constants.EXPONENT && (
                                <Col>
                                    <div className="experience">
                                        <label>
                                            <span style={{ color: 'red' }}>*</span>Experience
                                        </label>
                                        <input
                                            type="number"
                                            className="expInput"
                                            placeholder="Your Experience"
                                            value={experience}
                                            onChange={handleExperienceInput}
                                            onKeyDown={(e) => {
                                                const allowedKeys = ['.', 'Backspace'];
                                                for (let i = 0; i <= 9; i++) {
                                                    allowedKeys.push(i.toString());
                                                }
                                                if (!allowedKeys.includes(e.key)) {
                                                    e.preventDefault();
                                                }
                                                if (e.key === '.' && e.target.value.includes('.')) {
                                                    e.preventDefault();
                                                }
                                                if (e.key === '-' && e.target.selectionStart !== 0) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            step="any"
                                            min="1"
                                            max="50"
                                        />
                                    </div>
                                </Col>
                            )}

                            <Col>
                                <div className="gender-singleselect">
                                    <label>
                                        <span style={{ color: 'red' }}>*</span>Gender
                                    </label>
                                    <Select
                                        id="gender"
                                        options={genderList}
                                        getOptionLabel={(options) => options["name"]}
                                        getOptionValue={(options) => options["name"]}
                                        onChange={onSelectGender}
                                        placeholder="Select Gender"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* Country, State, City Selection */}
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <div className="country">
                                    <label>
                                        <span style={{ color: 'red' }}>*</span>Country
                                    </label>
                                    <Select
                                        options={Country.getAllCountries()}
                                        getOptionLabel={(options) => options["name"]}
                                        getOptionValue={(options) => options["name"]}
                                        value={selectedCountry}
                                        onChange={(item) => {
                                            setSelectedCountry(item);
                                        }}
                                        placeholder="Select Country"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                        isDisabled={true}
                                    />
                                </div>
                            </Col>

                            <Col>
                                <div className="state">
                                    <label>
                                        <span style={{ color: 'red' }}>*</span>State
                                    </label>
                                    <Select
                                        options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                        getOptionLabel={(options) => options["name"]}
                                        getOptionValue={(options) => options["name"]}
                                        value={selectedState}
                                        onChange={(item) => {
                                            setSelectedState(item);
                                        }}
                                        placeholder="Select State"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </div>
                            </Col>

                            <Col>
                                <div className="city">
                                    <label>
                                        <span style={{ color: 'red' }}>*</span>City
                                    </label>
                                    <Select
                                        options={City.getCitiesOfState(
                                            selectedState?.countryCode,
                                            selectedState?.isoCode
                                        )}
                                        getOptionLabel={(options) => options["name"]}
                                        getOptionValue={(options) => options["name"]}
                                        value={selectedCity}
                                        onChange={(item) => {
                                            setSelectedCity(item);
                                        }}
                                        placeholder="Select City"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* Error Message Display */}
                    <div>
                        {errMsgDiv && (
                            <div style={{ ...customCssForMsg }}>
                                <label>{errMsg}</label>
                            </div>
                        )}
                    </div>

                    <Button className="dashboard-button mt-3" onClick={handleMandatoryUpdateFieldsSubmit}>
                        Submit
                    </Button>
                </Container>
            </Modal.Body>
        </Modal>
    </div>
);
};

export default MandatoryUpdateFields;
